export const injectWatermark = (url: string) => {
  const parts = url.split("/");
  const index = parts.findIndex((part) => part === "static");
  if (index !== -1) {
    parts.splice(index + 1, 0, "watermark");
    return parts.join("/");
  }
  return url;
};

export const dataURLtoBlob = (dataURL: string) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const isValidUrl = (urlString: string) => {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};

export const fetchWithTimeout = async (
  url: string,
  { signal, ...options }: { signal?: AbortSignal } & RequestInit = {},
  timeoutMs = 5000
) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), timeoutMs);
  return promise.finally(() => clearTimeout(timeout));
};
