import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Comment, ActiveComment } from "@/types/social";
import { Nullable } from "@/types/common";

interface ActiveCommentState {
  id: number;
  type: string;
}

interface CommentsState {
  comments: Comment[];
  activeComment: Nullable<ActiveCommentState>;
}

export const initialState: CommentsState = {
  comments: [],
  activeComment: null,
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setComments(state, action: PayloadAction<Comment[]>) {
      state.comments = action.payload;
    },
    setActiveComment(state, action: PayloadAction<Nullable<ActiveComment>>) {
      state.activeComment = action.payload;
    },
  },
});

export const { setComments, setActiveComment } = commentsSlice.actions;

export default commentsSlice.reducer;
