import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from "react-redux";

import rootReducer from "./slices";

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof makeConfiguredStore>;
export type AppDispatch = AppStore["dispatch"];

export const makeConfiguredStore = (
  preloadedState?: PreloadedState<RootState>
) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const makeStore = makeConfiguredStore;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore = () => useStore<AppStore>();
