"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Nullable } from "@/types/common";

import { AppDispatch, RootState } from "@/redux/store";
import { setComments, generateAlert } from ".";

export const getComments = createAsyncThunk<
  void,
  {
    roomUUID?: string | null;
    articleSlug?: string | null;
    questionSlug?: string | null;
    quizSlug?: string | null;
    imageId?: number | null;
    genLevel?: boolean;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "comment/getComments",
  async (
    {
      roomUUID = null,
      articleSlug = null,
      questionSlug = null,
      quizSlug = null,
      imageId = null,
      genLevel = false,
    },
    { dispatch, getState }
  ) => {
    try {
      let response: any = { data: [] };
      if (roomUUID) {
        response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/public/${roomUUID}/`
        );
      } else if (articleSlug) {
        response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/article/${articleSlug}/`
        );
      } else if (questionSlug) {
        response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/question/${questionSlug}/`
        );
      } else if (quizSlug) {
        response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/quiz/${quizSlug}/`
        );
      } else if (imageId) {
        response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/image/${imageId}/?genLevel=${genLevel}`
        );
      }

      dispatch(setComments(response.data));
    } catch (error: unknown) {
      console.error(error);
      let errorMessage = "Failed to fetch comments";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);

export const createComment = createAsyncThunk<
  void,
  {
    parentId: Nullable<number>;
    roomUUID?: string | null;
    articleSlug?: string | null;
    questionSlug?: string | null;
    quizSlug?: string | null;
    imageId?: number | null;
    text: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "comment/createComment",
  async (
    {
      parentId,
      roomUUID = null,
      articleSlug = null,
      questionSlug = null,
      quizSlug = null,
      imageId = null,
      text,
    },
    { dispatch, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      let reqData: any;
      if (roomUUID) {
        reqData = JSON.stringify({ parentId, roomUUID, text });
        await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/comment`,
          reqData,
          config
        );
        dispatch(getComments({ roomUUID }));
      } else if (articleSlug) {
        reqData = JSON.stringify({ parentId, articleSlug, text });
        await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/article`,
          reqData,
          config
        );
        dispatch(getComments({ articleSlug }));
      } else if (questionSlug) {
        reqData = JSON.stringify({ parentId, questionSlug, text });
        await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/question`,
          reqData,
          config
        );
        dispatch(getComments({ questionSlug }));
      } else if (quizSlug) {
        reqData = JSON.stringify({ parentId, quizSlug, text });
        await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/quiz`,
          reqData,
          config
        );
        dispatch(getComments({ quizSlug }));
      } else if (imageId) {
        reqData = JSON.stringify({ parentId, imageId, text });
        await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/comment/image`,
          reqData,
          config
        );
        dispatch(getComments({ imageId }));
      }

      dispatch(
        generateAlert({
          text: "Comment created successfully!",
          type: "success",
        })
      );
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "Failed to create comment";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);

export const deleteComment = createAsyncThunk<
  void,
  {
    commentId: number;
    roomUUID?: string | null;
    articleSlug?: string | null;
    questionSlug?: string | null;
    quizSlug?: string | null;
    imageId?: number | null;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "comment/deleteComment",
  async (
    {
      commentId,
      roomUUID = null,
      articleSlug = null,
      questionSlug = null,
      quizSlug = null,
      imageId = null,
    },
    { dispatch }
  ) => {
    try {
      await axios.delete(
        `${process.env.NEXT_PUBLIC_API_URL}/comment/${commentId}`,
        {
          withCredentials: true,
        }
      );

      dispatch(
        generateAlert({
          text: "Comment deleted successfully!",
          type: "success",
        })
      );
      if (roomUUID) {
        dispatch(getComments({ roomUUID }));
      } else if (articleSlug) {
        dispatch(getComments({ articleSlug }));
      } else if (questionSlug) {
        dispatch(getComments({ questionSlug }));
      } else if (quizSlug) {
        dispatch(getComments({ quizSlug }));
      } else if (imageId) {
        dispatch(getComments({ imageId }));
      }
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "Failed to delete comment";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);

export const editComment = createAsyncThunk<
  void,
  {
    commentId: number;
    roomUUID?: string | null;
    articleSlug?: string | null;
    questionSlug?: string | null;
    quizSlug?: string | null;
    imageId?: number | null;
    text: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "comment/editComment",
  async (
    {
      commentId,
      roomUUID = null,
      articleSlug = null,
      questionSlug = null,
      quizSlug = null,
      imageId = null,
      text,
    },
    { dispatch }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const reqData = JSON.stringify({ text });

      await axios.patch(
        `${process.env.NEXT_PUBLIC_API_URL}/comment/${commentId}`,
        reqData,
        config
      );

      dispatch(
        generateAlert({
          text: "Comment edited successfully!",
          type: "success",
        })
      );
      if (roomUUID) {
        dispatch(getComments({ roomUUID }));
      } else if (articleSlug) {
        dispatch(getComments({ articleSlug }));
      } else if (questionSlug) {
        dispatch(getComments({ questionSlug }));
      } else if (quizSlug) {
        dispatch(getComments({ quizSlug }));
      } else if (imageId) {
        dispatch(getComments({ imageId }));
      }
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "Failed to edit comment";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);
