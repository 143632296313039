import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Notification } from "@/types/social";

interface NotificationsState {
  loading: boolean;
  notifications: Notification[];
}

export const initialState: NotificationsState = {
  notifications: [],
  loading: false,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<Notification[]>) {
      state.notifications = action.payload;
      state.loading = false;
    },
    setNotificationsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setNotifications, setNotificationsLoading } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
