import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Nullable } from "@/types/common";

interface BillingState {
  loading: boolean;
  error: Nullable<string>;
  redirectUrl: Nullable<string>;
}

export const initialState: BillingState = {
  loading: false,
  error: null,
  redirectUrl: null,
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setBillingLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBillingError: (state, action: PayloadAction<Nullable<string>>) => {
      state.error = action.payload;
    },
    setBillingRedirectUrl: (state, action: PayloadAction<Nullable<string>>) => {
      state.redirectUrl = action.payload;
      state.loading = false;
    },
  },
});

export const { setBillingLoading, setBillingError, setBillingRedirectUrl } =
  billingSlice.actions;

export default billingSlice.reducer;
