import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { Alert, NewAlert, AlertDialog, AlertDialogType } from "@/types/alert";

export const generateAlert = createAsyncThunk(
  "alerts/generateAlert",
  async ({ text, type, timeout = 5000 }: NewAlert, { dispatch }) => {
    const id = uuidv4();
    dispatch(addAlert({ id, text, type }));
    setTimeout(() => {
      dispatch(removeAlert(id));
    }, timeout);
  }
);

interface AlertState {
  alerts: Alert[];
  alertDialogs: AlertDialog[];
}

export const initialState: AlertState = {
  alerts: [],
  alertDialogs: [],
};

const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => {
      state.alerts.push(action.payload);
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      const index = state.alerts.findIndex(
        (alert) => alert.id === action.payload
      );
      if (index >= 0) {
        state.alerts.splice(index, 1);
      }
    },
    addAlertDialog: (
      state,
      action: PayloadAction<{
        title: string;
        type?: AlertDialogType;
        description?: string;
        data?: any;
        nextButtonText?: string;
        prevButtonText?: string;
        singleOption?: boolean;
      }>
    ) => {
      const id = uuidv4();
      const newAlertDialog = {
        id,
        title: action.payload.title,
        type: action.payload.type
          ? action.payload.type
          : AlertDialogType.Default,
        description: action.payload.description || "",
        data: action.payload.data,
        nextButtonText: action.payload.nextButtonText || "Continue",
        prevButtonText: action.payload.prevButtonText || "Cancel",
        singleOption: action.payload.singleOption ? true : false,
      };

      state.alertDialogs.push(newAlertDialog);
    },
    removeAlertDialog: (state, action) => {
      const index = state.alertDialogs.findIndex(
        (dialog) => dialog.id === action.payload
      );
      if (index >= 0) {
        state.alertDialogs.splice(index, 1);
      }
    },
  },
});

export const { addAlert, removeAlert, addAlertDialog, removeAlertDialog } =
  alertSlice.actions;

export default alertSlice.reducer;
