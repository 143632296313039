"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { AppDispatch, RootState } from "@/redux/store";
import {
  setArticles,
  setShopLoading,
  setShopGenerating,
  setSlug,
  generateAlert,
} from ".";

export const getArticles = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("article/getArticles", async (_, { dispatch, getState }) => {
  try {
    dispatch(setShopLoading(true));
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/article`
    );

    dispatch(setArticles(response.data.articles));
    dispatch(setShopLoading(false));
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to fetch articles";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    dispatch(setShopLoading(false));
  }
});

export const getUserArticles = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("article/getUserArticles", async (_, { dispatch }) => {
  try {
    dispatch(setShopLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/article/user`,
      config
    );

    dispatch(setArticles(response.data.articles));
    dispatch(setShopLoading(false));
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to fetch articles";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    dispatch(setShopLoading(false));
  }
});

export const createArticle = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch; state: RootState }
>("article/createArticle", async (input, { dispatch }) => {
  try {
    dispatch(setShopGenerating(true));

    const resp = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/article`,
      { input },
      {
        withCredentials: true,
      }
    );

    dispatch(
      generateAlert({
        text: "Article created successfully!",
        type: "success",
      })
    );
    dispatch(getArticles());
    dispatch(setShopGenerating(false));
    dispatch(setSlug(resp.data.seoSlug));
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "Failed to create article";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    dispatch(setShopGenerating(false));
  }
});
