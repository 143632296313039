import { combineReducers } from "@reduxjs/toolkit";

import alertReducer from "./alert";
import billingReducer from "./billing";
import commentReducer from "./comment";
import galleryReducer from "./gallery";
import notificationReducer from "./notifications";
import profileReducer from "./profile";
import roomReducer from "./room";
import shopReducer from "./shop";
import userReducer from "./user";

const rootReducer = combineReducers({
  alert: alertReducer,
  billing: billingReducer,
  comments: commentReducer,
  gallery: galleryReducer,
  notifications: notificationReducer,
  profile: profileReducer,
  room: roomReducer,
  shop: shopReducer,
  user: userReducer,
});

export default rootReducer;
