import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import cloneDeep from "lodash.clonedeep";

import { Generation } from "types/thread";
import { AppDispatch, RootState } from "redux/store";
import {
  setGalleryCursor,
  setGalleryGenerations,
  setGalleryLoading,
  setGalleryPaginationStop,
} from ".";

export const getGalleryGenerations = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("profile/getGalleryGenerations", async (_, { dispatch, getState }) => {
  try {
    dispatch(setGalleryLoading(true));
    const cursor = getState().gallery.cursor;

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/user/gallery/generations/?cursor=${cursor}`,
      {
        withCredentials: true,
      }
    );

    const newCursor = response.data.cursor;
    const existingGalleryGenerations = getState().gallery.generations;
    const newGalleryGenerations = response.data.generations;
    if (cursor > 1 && newGalleryGenerations.length < 1) {
      dispatch(setGalleryPaginationStop(true));
    }

    // Ensure that we don't add duplicate generations
    const updatedGalleryGenerations = cloneDeep(existingGalleryGenerations);
    newGalleryGenerations.forEach((generation: Generation) => {
      const index = updatedGalleryGenerations.findIndex(
        (item) => item.id === generation.id
      );
      if (index === -1) {
        updatedGalleryGenerations.push(generation);
      }
    });
    dispatch(setGalleryGenerations(updatedGalleryGenerations));
    dispatch(setGalleryCursor(newCursor));
    dispatch(setGalleryLoading(false));
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to fetch user profile generations";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    dispatch(setGalleryLoading(false));
  }
});
