"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { DiscordShareHookType } from "@/types/social";
import { QuizQuestion } from "@/types/quiz";
import { AppDispatch, RootState } from "@/redux/store";
import { generateAlert } from ".";

export const sendReport = createAsyncThunk<
  void,
  {
    url: string;
    generationUrls: string[];
    text: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "discord/sendReport",
  async ({ url, generationUrls, text }, { dispatch, getState }) => {
    const user = getState().user.user;
    if (!user) {
      dispatch(
        generateAlert({
          text: "You must be logged in to report.",
          type: "error",
        })
      );
      return;
    }

    const discordWebhookUrl =
      "https://discord.com/api/webhooks/1203899815889735710/zGYxSdEt2LDr7pr9s-BwoE889qBOSe_kb2h5eBeJm38whK4Uc0sssX51nbk4zPPEdhax";
    try {
      await axios.post(discordWebhookUrl, {
        content: `Generation reported by ${
          user.username
        } \n URL: ${url}\n Image URLs : ${generationUrls.join(
          ", "
        )} \n Reason: ${text}`,
      });
      dispatch(
        generateAlert({
          text: "Report has been submitted.",
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error sending message:", error);
      dispatch(
        generateAlert({
          text: "Failed to submit report.",
          type: "error",
        })
      );
    }
  }
);

export const requestGeneration = createAsyncThunk<
  void,
  {
    roomUUID?: string;
    text: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "discord/sendGenerationRequest",
  async ({ roomUUID, text }, { dispatch, getState }) => {
    const user = getState().user.user;
    if (!user) {
      dispatch(
        generateAlert({
          text: "You must be logged in to request generation assistance.",
          type: "error",
        })
      );
      return;
    }

    const discordWebhookUrl =
      "https://discord.com/api/webhooks/1221904239652241429/2TYSerr0wyRHOJxB0qa8gbRH5rimH6PhHQQ43rHA2VUuoIycwAhw-KjoZwnvyM3nRITo";
    try {
      await axios.post(discordWebhookUrl, {
        content: `Image generation requested by ${user.username} 
        \n  Image generation request description: ${text}`,
      });
      dispatch(
        generateAlert({
          text: "Your request has been submitted.",
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error sending message:", error);
      dispatch(
        generateAlert({
          text: "Failed to submit request.",
          type: "error",
        })
      );
    }
  }
);

export const sendFeatureRequest = createAsyncThunk<
  void,
  {
    text: string;
  },
  { dispatch: AppDispatch; state: RootState }
>("discord/sendFeatureRequest", async ({ text }, { dispatch, getState }) => {
  const user = getState().user.user;
  if (!user) {
    dispatch(
      generateAlert({
        text: "You must be logged in to request a feature.",
        type: "error",
      })
    );
    return;
  }

  const discordWebhookUrl =
    "https://discord.com/api/webhooks/1212646788469755934/CdhAwqJkD91c9wBLZGWgncxdVVwhGkDXS7cX9x4j4yF5jKQkTrbxNeMbWEgKi1Ajqjdo";
  try {
    await axios.post(discordWebhookUrl, {
      content: `${user.username} has requested this feature: ${text}`,
    });
    dispatch(
      generateAlert({
        text: "Feature has been requested.",
        type: "success",
      })
    );
  } catch (error) {
    console.error("Error sending message:", error);
    dispatch(
      generateAlert({
        text: "Failed to submit request.",
        type: "error",
      })
    );
  }
});

export const sendQuizFeedback = createAsyncThunk<
  void,
  {
    slug: string;
    questionIndex: number;
    question: QuizQuestion;
    text: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "discord/sendFeatureRequest",
  async ({ slug, questionIndex, question, text }, { dispatch, getState }) => {
    const user = getState().user.user;
    const discordWebhookUrl =
      "https://discord.com/api/webhooks/1262593913102012507/KvBAmBmVkC0qhu2HhHMctulAmlEpnhm9BC2aNVCIagpBbAC3UgROaEtQqvDntiuNHtM6";
    try {
      let correctAnswer = "N/A";
      if (
        question.answerIx !== undefined &&
        question.answerIx >= 0 &&
        question.answerIx < question.options.length
      ) {
        correctAnswer = question.options[question.answerIx];
      }
      await axios.post(discordWebhookUrl, {
        content: `${
          user ? `${user.username}` : "Anon"
        } has submitted this feedback
https://us.idyllic.app/quiz/${slug}\n
Question #${questionIndex},
${question.question}, \n
Correct answer: 
${
  question.answerIx !== undefined ? question.answerIx + 1 : "N/A"
}), ${correctAnswer}\n
User feedback: 
${text}`,
      });
      dispatch(
        generateAlert({
          text: "Feedback has been submitted.",
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error sending message:", error);
      dispatch(
        generateAlert({
          text: "Failed to submit feedback.",
          type: "error",
        })
      );
    }
  }
);

export const reportDictionaryWord = createAsyncThunk<
  void,
  {
    text: string;
    word: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "discord/reportDictionaryWord",
  async ({ text, word }, { dispatch, getState }) => {
    const user = getState().user.user;
    if (!user) {
      dispatch(
        generateAlert({
          text: "You must be logged in to report a dictionary word.",
          type: "error",
        })
      );
      return;
    }

    const discordWebhookUrl =
      "https://discord.com/api/webhooks/1227104766161719348/A5bvvIANG-n-UTn2FD4BeOGupIPCSs8KR-ElHDlbyFx5XZYvn9w9miIoGUP4wdKIgiJv";
    try {
      await axios.post(discordWebhookUrl, {
        content: `${user.username} has reported dictionary ${word} definition \n Reason: \n ${text}`,
      });
      dispatch(
        generateAlert({
          text: "Dictionary word reported.",
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error sending message:", error);
      dispatch(
        generateAlert({
          text: "Failed to submit request.",
          type: "error",
        })
      );
    }
  }
);

export const sendAmazonClick = createAsyncThunk<
  void,
  {
    pageUrl?: string;
    type?: "ad" | "shop image";
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "discord/sendAdClick",
  async ({ pageUrl = "", type = "ad" }, { getState }) => {
    const user = getState().user.user;

    const discordWebhookUrl =
      "https://discord.com/api/webhooks/1194150097743790080/GarY90XV546Uqep6oe3TTcS8Kovy4HNryBGLQc_fnDiopTgzQ9tgrtxThP-wtnRfkYTO";
    try {
      axios.post(discordWebhookUrl, {
        content: `${
          user ? `User ${user.username} (${user.id})` : "Non-user"
        } clicked amazon ${type}! \n Page: ${pageUrl}`,
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }
);

export const sendShareInteraction = createAsyncThunk<
  void,
  {
    url: string;
    currentPath: string;
    variant: DiscordShareHookType;
    platform?: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "discord/sendShareInteraction",
  async ({ url, currentPath, variant, platform }, { getState }) => {
    const user = getState().user.user;
    const userLabel = user ? `User ${user.username} (${user.id})` : "Non-user";

    let discordWebhookUrl = "";
    if (variant === "gen") {
      discordWebhookUrl =
        "https://discord.com/api/webhooks/1245878300694548632/1cqt3tc4LxIgAsqpUlB5h8bsalwn4BYGFAEglGG3XRdSqmyprExWIQUnmCX0hBM5Pd22";
    }
    if (variant === "dictionary") {
      discordWebhookUrl =
        "https://discord.com/api/webhooks/1245875061244821597/mwrZH37fbhdrlYDnXrs18YU7fi-b4A75_vbsINpsAlQrzs7IkgxZjRulEUoz361XwdYn";
    }

    if (variant === "question") {
      discordWebhookUrl =
        "https://discord.com/api/webhooks/1245878100227657889/14fA8wl40-_71AS8258sEQLnL_d1bfDLGMrZIJNm03F_dnhYQR9oS6ZjDv6f_-3266xf";
    }

    if (variant === "quiz") {
      discordWebhookUrl =
        "https://discord.com/api/webhooks/1249528984711073953/Au4S_2vX-9pYlEBWQWPnGGT6e2McX3Z81g0KBs512kpiYDVS7DxhE9Ykp_Pcw6rGRq95";
    }

    if (variant === "blog") {
      discordWebhookUrl =
        "https://discord.com/api/webhooks/1262352261661720576/UF2DPVgpowbGjSVxFNX1sTq_DmFhG5kCFEzzUlkoSFwswTX9MxN6VaClDkZyxPiJTVlZ";
    }

    const content = `${userLabel} clicked share button!\nPage: ${currentPath}\nShared URL: ${url}${
      platform ? `\nPlatform: ${platform}` : ""
    }`;

    try {
      if (discordWebhookUrl) {
        axios.post(discordWebhookUrl, { content });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }
);

export const sendBlogCreationNotification = createAsyncThunk<
  void,
  {
    quizSlug: string;
    lobbyUUID: string;
  },
  { dispatch: AppDispatch; state: RootState }
>("discord/sendAdClick", async ({ quizSlug, lobbyUUID }, { getState }) => {
  const user = getState().user.user;

  const discordWebhookUrl =
    "https://discord.com/api/webhooks/1263821468056289280/kWTw0LuWOk7_-GAAqSHe65NTDU-luKbzkVkKByEmV9tgaGoRd2C468as9eCQ8YPq-gQs";
  try {
    axios.post(discordWebhookUrl, {
      content: `${
        user ? `User ${user.username} (${user.id})` : "Non-user"
      } created a quiz lobby! \n Lobby UUID: ${lobbyUUID}\n Quiz URL: ${
        process.env.NEXT_PUBLIC_BASE_URL
      }/quiz/${quizSlug}`,
    });
  } catch (error) {
    console.error("Error sending message:", error);
  }
});
