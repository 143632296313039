import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Article } from "@/types/shop";

interface ArticlesState {
  loading: boolean;
  generating: boolean;
  articles: Article[];
  slug: string | null;
}

export const initialState: ArticlesState = {
  loading: false,
  generating: false,
  articles: [],
  slug: null,
};

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setShopLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setShopGenerating(state, action: PayloadAction<boolean>) {
      state.generating = action.payload;
    },
    setArticles(state, action: PayloadAction<Article[]>) {
      state.articles = action.payload;
    },
    setSlug(state, action: PayloadAction<string | null>) {
      state.slug = action.payload;
    },
  },
});

export const { setShopLoading, setShopGenerating, setArticles, setSlug } =
  articlesSlice.actions;

export default articlesSlice.reducer;
