import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Nullable } from "@/types/common";
import { User, Quota } from "@/types/user";

interface UserState {
  isAuthenticated: boolean;
  authLoading: boolean;
  user: Nullable<User>;
  userLoading: boolean;
  updateLoading: boolean;
  updateError: string;
  promoCode: Nullable<string>;
  promoCodeError: string;
  quotas: Quota[];
}

export const initialState: UserState = {
  isAuthenticated: false,
  authLoading: true,
  user: null,
  userLoading: false,
  updateLoading: false,
  updateError: "",
  promoCode: null,
  promoCodeError: "",
  quotas: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
      state.authLoading = false;
    },
    setAuthLoading(state, action: PayloadAction<boolean>) {
      state.authLoading = action.payload;
    },
    setUser(state, action: PayloadAction<Nullable<User>>) {
      state.user = action.payload;
      state.userLoading = false;
    },
    setUserLoading(state, action: PayloadAction<boolean>) {
      state.userLoading = action.payload;
    },
    setUserUpdateLoading(state, action: PayloadAction<boolean>) {
      state.updateLoading = action.payload;
    },
    setUserUpdateError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
    },
    setPromoCode(state, action: PayloadAction<Nullable<string>>) {
      if (action.payload === null) {
        localStorage.removeItem("promoCode");
      } else {
        localStorage.setItem("promoCode", action.payload);
      }
      state.promoCode = action.payload;
    },
    setPromoCodeError(state, action: PayloadAction<string>) {
      state.promoCodeError = action.payload;
    },
    setQuotas(state, action: PayloadAction<Quota[]>) {
      state.quotas = action.payload;
    },
  },
});

export const {
  setIsAuthenticated,
  setAuthLoading,
  setUser,
  setUserLoading,
  setUserUpdateLoading,
  setUserUpdateError,
  setPromoCode,
  setPromoCodeError,
  setQuotas,
} = userSlice.actions;

export default userSlice.reducer;
