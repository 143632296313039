"use client";

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "@/redux/store";
import {
  STRIPE_IDS,
  STRIPE_QUIZ_WIDGET_PLAN_IDS,
} from "@/constants/subscription";
import {
  setBillingError,
  setBillingLoading,
  setBillingRedirectUrl,
  generateAlert,
  authenticateUser,
} from "./";
import { PurchaseStatusResponse } from "@/types/billing";

const environment =
  process.env.NODE_ENV === "development" ? "development" : "production";

const getStripeProductId = (
  plan: "unlimited" | "starter" | "premium" | "professional" | "business",
  frequency: "monthly" | "yearly"
) => {
  return STRIPE_IDS[environment][plan][frequency];
};

const getStripeQuizWidgetProductId = (plan: "article quiz" | "site quiz") => {
  return STRIPE_QUIZ_WIDGET_PLAN_IDS[environment][plan];
};

export const subscribeWithStripe = createAsyncThunk<
  string | null,
  {
    plan: "unlimited" | "starter" | "premium" | "professional" | "business";
    paymentFrequency: "monthly" | "yearly";
    discountCode?: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "billing/subscribeWithStripe",
  async (
    { plan = "premium", paymentFrequency = "yearly", discountCode },
    { dispatch }
  ) => {
    try {
      dispatch(setBillingLoading(true));
      dispatch(setBillingError(null));

      const priceId = getStripeProductId(plan, paymentFrequency);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const requestBody: { [key: string]: any } = {
        mode: "subscription",
        priceId: priceId,
      };

      if (discountCode) {
        requestBody.discountCode = discountCode;
      }

      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe`,
        requestBody,
        config
      );

      return response.data.url;
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "An error occurred while creating checkout session!";
      dispatch(setBillingLoading(false));
      dispatch(setBillingError(errorMessage));
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      return null;
    }
  }
);

export const stripeOneOffPayment = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("billing/stripeOneOffPayment", async (_, { dispatch }) => {
  try {
    dispatch(setBillingLoading(true));
    dispatch(setBillingError(null));

    const priceId =
      environment === "development"
        ? "price_1Q2NoVIegQSKNzkSfLtvmNio"
        : "price_1Q2PBsIegQSKNzkSplMaF6WC";

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const requestBody: { [key: string]: any } = {
      mode: "payment",
      priceId: priceId,
    };

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe`,
      requestBody,
      config
    );

    dispatch(setBillingRedirectUrl(response.data.url));
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "An error occurred while processing payment!";
    dispatch(setBillingLoading(false));
    dispatch(setBillingError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const checkStripePurchaseStatus = createAsyncThunk<
  PurchaseStatusResponse | null,
  {
    purchaseType: "subscription" | "payment";
    sessionId: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "billing/checkStripePurchaseStatus",
  async ({ purchaseType, sessionId }, { dispatch }) => {
    try {
      dispatch(setBillingLoading(true));
      dispatch(setBillingError(null));

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe/confirmation/${purchaseType}/${sessionId}`,
        config
      );

      let successMessage = "Purchase successful!";
      if (purchaseType === "subscription") {
        successMessage = "Subscription successful!";
      }
      dispatch(authenticateUser());
      dispatch(setBillingLoading(false));
      dispatch(generateAlert({ text: successMessage, type: "success" }));
      return response.data;
    } catch (error: unknown) {
      console.info(error);
      const errorMessage = "No valid purchase found!";
      dispatch(setBillingLoading(false));
      dispatch(setBillingError(errorMessage));
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      return null;
    }
  }
);

export const cancelSubscription = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("billing/cancelSubscription", async (_, { dispatch }) => {
  try {
    dispatch(setBillingLoading(true));
    dispatch(setBillingError(null));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/billing`, config);
    dispatch(setBillingRedirectUrl("/settings/manage-subscription"));
    dispatch(
      generateAlert({ text: "Subscription cancelled!", type: "success" })
    );
    dispatch(authenticateUser());
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "Failed to cancel subscription!";
    dispatch(setBillingLoading(false));
    dispatch(setBillingError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const checkPaypalSubscription = createAsyncThunk<
  string | null,
  string,
  { dispatch: AppDispatch; state: RootState }
>("billing/checkPaypalSubscription", async (subscriptionId, { dispatch }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/billing/paypal/subscriptions/${subscriptionId}/confirmation`,
      {},
      config
    );

    return "/payment-success/paypal";
  } catch (error: unknown) {
    console.info(error);
    const errorMessage = "No valid purchase found!";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    return null;
  }
});

export const createQuizWidgetStripeSession = createAsyncThunk<
  string | null,
  {
    plan: "article quiz" | "site quiz";
    email: string;
    discountCode?: string;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "billing/createQuizWidgetStripeSession",
  async ({ plan, email, discountCode }, { dispatch }) => {
    try {
      dispatch(setBillingLoading(true));
      dispatch(setBillingError(null));

      const priceId = getStripeQuizWidgetProductId(plan);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const mode = "payment";

      const requestBody: { [key: string]: any } = {
        priceId: priceId,
        email,
        mode,
      };

      if (discountCode) {
        requestBody.discountCode = discountCode;
      }

      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe/anonymous`,
        requestBody,
        config
      );

      return response.data.url;
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "An error occurred while creating checkout session!";
      dispatch(setBillingLoading(false));
      dispatch(setBillingError(errorMessage));
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      return null;
    }
  }
);

export const getStripePaymentDetails = createAsyncThunk<
  any,
  string,
  { dispatch: AppDispatch; state: RootState }
>("billing/getStripePaymentDetails", async (sessionId, { dispatch }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe/confirmation/${sessionId}`,
      config
    );

    return response.data;
  } catch (error: unknown) {
    console.info(error);
    const errorMessage = "No valid purchase found!";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    return null;
  }
});

export const getPaypalPaymentDetails = createAsyncThunk<
  {
    transactionId: string;
    customerEmail: string;
    price: string;
    currency: string;
    paymentDate: string;
  },
  void,
  { dispatch: AppDispatch; state: RootState }
>("billing/getPaypalPaymentDetails", async (_, { dispatch }) => {
  try {
    await dispatch(authenticateUser());
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/billing/paypal/confirmation`,
      config
    );

    return response.data;
  } catch (error: unknown) {
    console.info(error);
    const errorMessage = "Failed to fetch payment details!";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    return null;
  }
});
