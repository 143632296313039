import { ResourceName, PeriodType } from "@/types/user";

export const FREE_FEATURES = [
  "20 Image Generations Per Day",
  "1 Image Edit Per Day",
  "Generate 1 Image at a Time",
  "All Generations Are Made Public",
  "Ads Are Shown",
];

export const STARTER_FEATURES = [
  "100 Daily Image Generations",
  "10 Daily HD Image generations",
  "10 Daily Image Variations",
  "10 Monthly Image Enhances",
  "10 Daily Image Edits",
  "Generate 4 at Once (Basic Model)",
  "Private Toggle for All Generations",
  "Access to NSFW Filtered Content",
  "No Ads",
  "For Personal Use Only",
];

export const PREMIUM_FEATURES = [
  "500 Daily Image Generations",
  "100 Daily HD Image generations",
  "50 Daily Image Variations",
  "20 Monthly Image Enhances",
  "50 Daily Image Edits",
  "50 Monthly HDR+ Generations",
  "Private Toggle for All Generations",
  "Access to NSFW Filtered Content",
  "No Ads",
  "Commercial Use License",
];

export const PROFESSIONAL_FEATURES = [
  "1000 Daily Image Generations",
  "500 Daily HD Image Generations",
  "100 Monthly Image Variations",
  "100 Monthly Image Enhances",
  "100 Daily Image Edits",
  "100 Monthly HDR+ Generations",
  "Private Toggle for All Generations",
  "Access to NSFW Filtered Content",
  "No Ads",
  "Commercial Use License",
];

export const BUSINESS_FEATURES = [
  "10000 Daily Image Generations",
  "5000 Daily HD Image Generations",
  "1000 Monthly Image Variations",
  "1000 Monthly Image Enhances",
  "1000 Daily Image Edits",
  "1000 Monthly HDR+ Generations",
  "Private Toggle for All Generations",
  "Access to NSFW Filtered Content",
  "No Ads",
  "Commercial Use License",
  "20 Monthly Custom Model Trainings",
];

export const STRIPE_IDS = {
  development: {
    // Legacy Plan
    unlimited: {
      monthly: "price_1ONN3GIegQSKNzkSMnpfvBjw",
      yearly: "price_1OYSeUIegQSKNzkS11KLr0xz",
    },
    starter: {
      monthly: "price_1Q4VaeIegQSKNzkS4PR3pxa2",
      yearly: "price_1Q4VcUIegQSKNzkSxgg9DPge",
    },
    premium: {
      monthly: "price_1Q4VdFIegQSKNzkSbLMFZzk2",
      yearly: "price_1Q4VgpIegQSKNzkSa8kkPgiF",
    },
    professional: {
      monthly: "price_1Q4WKiIegQSKNzkSOjOpklXq",
      yearly: "price_1Q4WLaIegQSKNzkSluAAAYyq",
    },
    business: {
      monthly: "price_1Q3SURIegQSKNzkSMLMzHR2c",
      yearly: "price_1Q3SVuIegQSKNzkSjKKlB68e",
    },
  },
  production: {
    // Legacy Plan
    unlimited: {
      monthly: "price_1ONN54IegQSKNzkSYsx5kH9y",
      yearly: "price_1OYSddIegQSKNzkS5UY4LAYD",
    },
    starter: {
      monthly: "price_1Q4WOIIegQSKNzkSpIndwiGA",
      yearly: "price_1Q4WP0IegQSKNzkScuE6i28m",
    },
    premium: {
      monthly: "price_1Q4WPiIegQSKNzkSiGCRESA4",
      yearly: "price_1Q4WSfIegQSKNzkSZhGapMN2",
    },
    professional: {
      monthly: "price_1Q4WTcIegQSKNzkSIeHyOxue",
      yearly: "price_1Q4WU3IegQSKNzkStuf7lU8a",
    },
    business: {
      monthly: "price_1Q3TF3IegQSKNzkSqV6MlKjb",
      yearly: "price_1Q3TJFIegQSKNzkSZCShJ0T5",
    },
  },
};

export const STRIPE_QUIZ_WIDGET_PLAN_IDS = {
  development: {
    "article quiz": "price_1QLHGYIegQSKNzkSa5mz7vfy",
    "site quiz": "price_1QLHHJIegQSKNzkSZTryAIgB",
  },
  production: {
    "article quiz": "price_1QAzAlIegQSKNzkSDeXSqJht",
    "site quiz": "price_1QLHFSIegQSKNzkSj6Z5teBI",
  },
};

export const PAYPAL_IDS = {
  development: {
    starter: {
      monthly: "P-6G796448JK173604HM4PQFGA",
      yearly: "P-1CY328063J873153CM4PQFSQ",
    },
    premium: {
      monthly: "P-5XW35025BW662305YM4PQICI",
      yearly: "P-6N503396T3504820NM4PQIKY",
    },
    professional: {
      monthly: "P-43L99319MV512102DM4PQF7I",
      yearly: "P-1UE44607SB251184TM4PQHWI",
    },
    business: {
      monthly: "P-08G21467VN7609307M4PQEUY",
      yearly: "P-5LF47410YN953880CM4PQE3A",
    },
  },
  production: {
    starter: {
      monthly: "P-0LV65942CM7361057M4PPXNI",
      yearly: "P-4GK46108KN8915237M4PPYLY",
    },
    premium: {
      monthly: "P-6LX77674E8944244VM4PP6XI",
      yearly: "P-0C555392SE525282HM4PP7UI",
    },
    professional: {
      monthly: "P-3EA39448XH361453MM4PP2SI",
      yearly: "P-3EL92074K49348828M4PP26Q",
    },
    business: {
      monthly: "P-6CX32362TM713444AM4PPSMA",
      yearly: "P-7F864973WL463370UM4PPVQQ",
    },
  },
};

export const PAYPAL_DISCOUNT_IDS = {
  development: {
    starter: {
      monthly: "P-6J774808J10459334M4PT3KY",
      yearly: "P-1JK033092L2243637M4PT3WQ",
    },
    premium: {
      monthly: "P-1B451288DW678034RM4PT5BI",
      yearly: "P-0YF35387814569341M4PT5XA",
    },
    professional: {
      monthly: "P-96M72430HE850542DM4PT4KQ",
      yearly: "P-1CX934395X205080CM4PT4WA",
    },
    business: {
      monthly: "P-1LW33049NF1277523M4PTZ6Q",
      yearly: "P-1H515380WD840180GM4PT22A",
    },
  },
  production: {
    starter: {
      monthly: "P-0JF33297NM679860PM4PTUKA",
      yearly: "P-7HA52634A9112994EM4PTVHA",
    },
    premium: {
      monthly: "P-7JV61875UX035003CM4PTWWQ",
      yearly: "P-9LR278586V589033NM4PTXPI",
    },
    professional: {
      monthly: "P-6KB654997P363563PM4PTVXY",
      yearly: "P-4NP3288754330031BM4PTWII",
    },
    business: {
      monthly: "P-07X1999595417102TM4PTS5I",
      yearly: "P-9SL56598SK511415YM4PTTOA",
    },
  },
};

export const PRICES = {
  monthly: {
    starter: 17,
    premium: 29,
    professional: 79,
    business: 299,
  },
  yearly: {
    starter: 7,
    premium: 12,
    professional: 33,
    business: 142,
  },
};

export const ANNUAL_PRICES = {
  starter: 89,
  premium: 149,
  professional: 399,
  business: 1699,
};

export const QUOTA_RESOURCE_MAP: Record<ResourceName, string> = {
  gen: "Generation",
  hd: "HD",
  enhance: "Enhance",
  remix: "Remix",
  fluxPro: "HDR+",
  hdTurbo: "HD Turbo",
  nsfw: "NSFW",
  inPaint: "Replace Area",
  upscale: "Upscale",
};

export const QUOTA_PERIOD_MAP: Record<PeriodType, string> = {
  daily: "Daily",
  monthly: "Monthly",
};

export const TESTIMONIALS = [
  {
    testimonial:
      "I've been using Idyllic for creating images inspired by games I love. no complaints it's amazing to use.",
    name: "Lucas",
    totalCreations: 2406,
    starRating: 5,
  },
  {
    testimonial:
      "You gave me EXACTLY what I was looking for and I wasn't sure how to describe what I was after.",
    name: "Lena",
    totalCreations: 498,
    starRating: 5,
  },
  {
    testimonial:
      "I use Idyllic for teaching, and it's been great. Keep up the good work!",
    name: "Michael",
    totalCreations: 236,
    starRating: 4,
  },
];

export const FAQ_LIST = [
  {
    question: "How do I get 6+ months free when signing up?",
    answer:
      "If you sign up for the yearly plan, the pricing is over 50% cheaper, which means 6+ months free compared to regular monthly pricing. Make sure to select yearly and you are saving a lot of money! This is our way of thanking you for your trust in Idyllic and lets us reinvest your money to improve the product.",
  },
  {
    question: "Is the payment service secure?",
    answer:
      "Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards.",
  },
  {
    question: "Can I get a receipt or invoice?",
    answer:
      "Yes, after payment you get a receipt and invoice. We can also send you one on request.",
  },
  {
    question: "Can I get a refund?",
    answer:
      "Unfortunately, we cannot offer refunds as costs incurred for provisioning GPU resources required to run our AI models are extremely high and are triggered immediately upon signup to ensure smooth operation of the site for all users. During sign up you agree to withhold your right to refund for this reason. You can cancel any time though and you will have access to your current plan until the end of the billing cycle without incurring any additional charges.",
  },
];

export const FEATURE_LIST = [
  {
    name: "Models",
    images: [
      "https://files.idyllic.app/files/static/2582197",
      "https://files.idyllic.app/files/static/2579957",
    ],
    description:
      "Create versatile model visuals with ease. Perfect for fashion, product showcases, or character design, you can explore every style you envision, capturing the look and feel that matches your creative projects.",
    totalPhotos: "227k",
    totalThisWeek: "10.3k",
  },
  {
    name: "Fantasy",
    images: [
      "https://files.idyllic.app/files/static/2582159",
      "https://files.idyllic.app/files/static/2582130",
    ],
    description:
      "Bring your mythical worlds to life with limitless fantasy landscapes, characters, and creatures. Dive into a realm of creativity and make breathtaking visuals that spark the imagination, taking your stories to magical new heights.",
    totalPhotos: "199k",
    totalThisWeek: "7.9k",
  },
  {
    name: "Image Editing",
    images: [
      "https://files.idyllic.app/files/static/2582128",
      "https://files.idyllic.app/files/static/2582137",
    ],
    description:
      "Transform and refine any part of your image with intuitive inpainting. Adjust details effortlessly to ensure every element matches your creative direction, allowing you to reshape and enhance your artwork as you go.",
    totalPhotos: "22k",
    totalThisWeek: "779",
  },
  {
    name: "Cars",
    images: [
      "https://files.idyllic.app/files/static/2579931",
      "https://files.idyllic.app/files/static/2582052",
    ],
    description:
      "Explore endless car designs, from futuristic concepts to nostalgic classics. Create stunning visuals that reflect your passion for automotive design, letting your imagination take the wheel as you craft unique vehicles for any scenario.",
    totalPhotos: "89k",
    totalThisWeek: "4.2k",
  },
  {
    name: "Book Covers",
    images: [
      "https://files.idyllic.app/files/static/2355133",
      "https://files.idyllic.app/files/static/2579136",
    ],
    description:
      "Instantly design captivating book covers that evoke the essence of your story. Attract readers at a glance with stunning, professionally crafted visuals that encapsulate the magic of your narrative.",
    totalPhotos: "91k",
    totalThisWeek: "3.1k",
  },
  {
    name: "Image Enhancements",
    images: [
      "https://files.idyllic.app/files/static/2580360",
      "https://files.idyllic.app/files/static/2580367",
    ],
    description:
      "Turn good to GREAT! Bring out the finest details in any image. Instantly enhance and upscale images to showcase your vision in vibrant, high-quality resolution.",
    totalPhotos: "1.7k",
    totalThisWeek: "83",
  },
  {
    name: "Movie Posters",
    images: [
      "https://files.idyllic.app/files/static/2374656",
      "https://files.idyllic.app/files/static/1926847",
    ],
    description:
      "Craft cinematic movie posters that convey your film's genre and mood with striking imagery. Effortlessly compose visually stunning artwork that hints at the adventure, drama, or thrill waiting within your film.",
    totalPhotos: "110k",
    totalThisWeek: "4.4k",
  },
  {
    name: "Image Variations",
    images: [
      "https://files.idyllic.app/files/static/2581363",
      "https://files.idyllic.app/files/static/2581366",
    ],
    description:
      "Kickstart your creativity by providing a description, an image, or both. Share your inspiration to craft something stunning that reflects your unique vision.",
    totalPhotos: "27k",
    totalThisWeek: "1.1k",
  },
];
