import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Generation } from "types/thread";

interface GalleryState {
  loading: boolean;
  generations: Generation[];
  cursor: number;
  galleryScrollPosition: number;
  paginationStop: boolean;
}

export const initialState: GalleryState = {
  loading: false,
  generations: [],
  cursor: 1,
  galleryScrollPosition: 0,
  paginationStop: false,
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setGalleryLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setGalleryGenerations(state, action: PayloadAction<Generation[]>) {
      state.generations = action.payload;
    },
    setGalleryCursor(state, action: PayloadAction<number>) {
      state.cursor = action.payload;
    },
    setGalleryScrollPosition(state, action: PayloadAction<number>) {
      state.galleryScrollPosition = action.payload;
    },
    setGalleryPaginationStop(state, action: PayloadAction<boolean>) {
      state.paginationStop = action.payload;
    },
    resetGallery(state) {
      state.generations = [];
      state.cursor = 1;
      state.galleryScrollPosition = 0;
      state.paginationStop = false;
    },
  },
});

export const {
  setGalleryLoading,
  setGalleryGenerations,
  setGalleryCursor,
  setGalleryScrollPosition,
  setGalleryPaginationStop,
  resetGallery,
} = gallerySlice.actions;

export default gallerySlice.reducer;
