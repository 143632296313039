"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { AppDispatch, RootState } from "@/redux/store";
import { generateAlert, setUserFollow } from ".";
import { PageType } from "@/types/common";

export const createFollow = createAsyncThunk<
  boolean,
  {
    followingUserId: number;
    pageType: PageType;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "follow/createFollow",
  async ({ followingUserId, pageType }, { dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const reqData = JSON.stringify({ followingUserId });

      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/follow`,
        reqData,
        config
      );

      if (pageType === "thread") {
        dispatch(setUserFollow(response.data));
      }

      return response.data;
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "Unable to follow user, please try again later";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      return false;
    }
  }
);

export const deleteFollow = createAsyncThunk<
  boolean,
  { followId: number; pageType: "profile" | "thread" },
  { dispatch: AppDispatch; state: RootState }
>("follow/deleteFollow", async ({ followId, pageType }, { dispatch }) => {
  try {
    await axios.delete(
      `${process.env.NEXT_PUBLIC_API_URL}/follow/${followId}`,
      {
        withCredentials: true,
      }
    );

    if (pageType === "thread") {
      dispatch(setUserFollow(null));
    }

    return true;
  } catch (error: unknown) {
    console.error(error);
    const errorMessage =
      "Unable to stop following user, please try again later";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    return false;
  }
});
