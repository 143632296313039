import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Nullable } from "types/common";
import { Generation } from "types/thread";
import { ProfileUserFollow } from "types/social";

interface ProfileState {
  generationsCursor: number;
  order: string;
  loading: boolean;
  selectedUsername: Nullable<string>;
  generations: Generation[];
  loadingGenerations: boolean;
  followers: ProfileUserFollow[];
  following: ProfileUserFollow[];
  followersCursor: number;
  followingCursor: number;
}

export const initialState: ProfileState = {
  generationsCursor: 1,
  order: "popular",
  loading: true,
  selectedUsername: "",
  generations: [],
  loadingGenerations: false,
  followers: [],
  following: [],
  followersCursor: 1,
  followingCursor: 1,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileGenerations(state, action: PayloadAction<Generation[]>) {
      state.generations = action.payload;
    },
    setProfileGenerationsCursor(state, action: PayloadAction<number>) {
      state.generationsCursor = action.payload;
    },
    setProfileGenerationsLoading(state, action: PayloadAction<boolean>) {
      state.loadingGenerations = action.payload;
    },
    setProfileOrder(state, action: PayloadAction<string>) {
      state.order = action.payload;
    },
    resetProfile: (state) => {
      state.generations = [];
      state.generationsCursor = 1;
      state.followers = [];
      state.following = [];
      state.followersCursor = 1;
      state.followingCursor = 1;
      state.loading = true;
    },
    setProfileLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedUsername(state, action: PayloadAction<Nullable<string>>) {
      state.selectedUsername = action.payload;
    },
    setFollowers(state, action: PayloadAction<ProfileUserFollow[]>) {
      state.followers = action.payload;
    },
    setFollowing(state, action: PayloadAction<ProfileUserFollow[]>) {
      state.following = action.payload;
    },
    setFollowersCursor(state, action: PayloadAction<number>) {
      state.followersCursor = action.payload;
    },
    setFollowingCursor(state, action: PayloadAction<number>) {
      state.followingCursor = action.payload;
    },
  },
});

export const {
  setProfileGenerations,
  setProfileGenerationsCursor,
  setProfileGenerationsLoading,
  setProfileOrder,
  setProfileLoading,
  setSelectedUsername,
  resetProfile,
  setFollowers,
  setFollowing,
  setFollowersCursor,
  setFollowingCursor,
} = profileSlice.actions;

export default profileSlice.reducer;
