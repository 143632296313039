"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { AppDispatch, RootState } from "@/redux/store";
import { generateAlert } from ".";

export const createImageRating = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("rating/createImageRating", async (imageId, { dispatch }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const reqData = JSON.stringify({ imageId, type: "upvote" });

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/rating`,
      reqData,
      config
    );

    return response.data;
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "Unable to like this image, please try again later";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const deleteRating = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("rating/deleteRating", async (ratingId, { dispatch }) => {
  try {
    const response = await axios.delete(
      `${process.env.NEXT_PUBLIC_API_URL}/rating/${ratingId}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "Unable to remove rating, please try again later";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const createQuestionRating = createAsyncThunk<
  void,
  { questionId: number; type: "upvote" | "downvote" },
  { dispatch: AppDispatch; state: RootState }
>("rating/createQuestionRating", async ({ questionId, type }, { dispatch }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const reqData = JSON.stringify({ questionId, type });

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/rating/question`,
      reqData,
      config
    );

    return response.data;
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = `Unable to ${type} this question, please try again later`;
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const updateQuestionRating = createAsyncThunk<
  void,
  { ratingId: number; type: "upvote" | "downvote" },
  { dispatch: AppDispatch; state: RootState }
>("rating/updateQuestionRating", async ({ ratingId, type }, { dispatch }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const reqData = JSON.stringify({ type });

    const response = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/rating/question/${ratingId}`,
      reqData,
      config
    );

    return response.data;
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = `Unable to ${type} this question, please try again later`;
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const deleteQuestionRating = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("rating/deleteQuestionRating", async (ratingId, { dispatch }) => {
  try {
    const response = await axios.delete(
      `${process.env.NEXT_PUBLIC_API_URL}/rating/question/${ratingId}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "Unable to remove rating, please try again later";
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});
