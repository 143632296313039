import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import cloneDeep from "lodash.clonedeep";

import { Generation } from "types/thread";
import { ProfileUserFollow } from "types/social";
import { AppDispatch, RootState } from "redux/store";
import {
  setProfileGenerations,
  setProfileGenerationsLoading,
  setProfileGenerationsCursor,
  setProfileLoading,
  setFollowers,
  setFollowing,
  setFollowersCursor,
  setFollowingCursor,
  generateAlert,
} from ".";

export const getProfileGenerations = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("profile/getProfileGenerations", async (_, { dispatch, getState }) => {
  try {
    dispatch(setProfileGenerationsLoading(true));
    const username = getState().profile.selectedUsername;

    const cursor = getState().profile.generationsCursor;

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/user/profile/generations/?username=${username}&cursor=${cursor}&limit=50`,
      {
        withCredentials: true,
      }
    );

    const newCursor = response.data.cursor;
    const existingProfileGenerations = getState().profile.generations;
    const newProfileGenerations = response.data.generations;

    // Ensure that we don't add duplicate generations
    const updatedProfileGenerations = cloneDeep(existingProfileGenerations);
    newProfileGenerations.forEach((generation: Generation) => {
      const index = updatedProfileGenerations.findIndex(
        (item) => item.id === generation.id
      );
      if (index === -1) {
        updatedProfileGenerations.push(generation);
      }
    });
    dispatch(setProfileGenerations(updatedProfileGenerations));
    dispatch(setProfileGenerationsCursor(newCursor));
    dispatch(setProfileGenerationsLoading(false));
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to fetch user profile generations";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    dispatch(setProfileGenerationsLoading(false));
  }
});

export const getProfileFollowers = createAsyncThunk<
  void,
  { username: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "profile/getProfileFollowers",
  async ({ username }, { dispatch, getState }) => {
    try {
      dispatch(setProfileLoading(true));
      const cursor = getState().profile.followersCursor;

      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/follow/followers/?username=${username}&cursor=${cursor}&limit=50`,
        {
          withCredentials: true,
        }
      );

      const newCursor = response.data.cursor;
      const existingFollowers = getState().profile.followers;
      const newFollowers = response.data.userFollowers;

      // Ensure that we don't add duplicate follows
      const updatedProfileFollowers = cloneDeep(existingFollowers);
      newFollowers.forEach((follow: ProfileUserFollow) => {
        const index = updatedProfileFollowers.findIndex(
          (item) => item.id === follow.id
        );
        if (index === -1) {
          updatedProfileFollowers.push(follow);
        }
      });
      dispatch(setFollowers(updatedProfileFollowers));
      dispatch(setFollowersCursor(newCursor));
      dispatch(setProfileLoading(false));
    } catch (error: unknown) {
      console.error(error);
      let errorMessage = "Failed to fetch user followers";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      dispatch(setProfileLoading(false));
    }
  }
);

export const getProfileFollowing = createAsyncThunk<
  void,
  { username: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "profile/getProfileFollowing",
  async ({ username }, { dispatch, getState }) => {
    try {
      dispatch(setProfileLoading(true));
      const cursor = getState().profile.followersCursor;

      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/follow/following/?username=${username}&cursor=${cursor}`,
        {
          withCredentials: true,
        }
      );

      const newCursor = response.data.cursor;
      const existingFollowing = getState().profile.following;
      const newFollowers = response.data.userFollowing;

      // Ensure that we don't add duplicate follows
      const updatedProfileFollowing = cloneDeep(existingFollowing);
      newFollowers.forEach((follow: ProfileUserFollow) => {
        const index = updatedProfileFollowing.findIndex(
          (item) => item.id === follow.id
        );
        if (index === -1) {
          updatedProfileFollowing.push(follow);
        }
      });
      dispatch(setFollowing(updatedProfileFollowing));
      dispatch(setFollowingCursor(newCursor));
      dispatch(setProfileLoading(false));
    } catch (error: unknown) {
      console.error(error);
      let errorMessage = "Failed to fetch user following";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      dispatch(setProfileLoading(false));
    }
  }
);
