"use client";

import { Nullable } from "types/common";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { AppDispatch, RootState } from "@/redux/store";

import { generateAlert, getRoomById } from ".";

export const updateFeedBackRating = createAsyncThunk<
  void,
  { feedbackRating: Nullable<string>; generationId: number },
  { dispatch: AppDispatch; state: RootState }
>(
  "feedback/updateFeedbackRating",
  async ({ feedbackRating, generationId }, { dispatch, getState }) => {
    try {
      const roomId = getState().room.roomId;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const reqData = JSON.stringify({ feedbackRating, generationId });

      await axios.patch(
        `${process.env.NEXT_PUBLIC_API_URL}/generation/feedbackRating`,
        reqData,
        config
      );

      dispatch(
        generateAlert({
          text: "Feedback updated successfully!",
          type: "success",
        })
      );
      if (roomId) dispatch(getRoomById(roomId));
    } catch (error: any) {
      console.error(error);
      const errorMessage =
        error.response?.data?.errors[0]?.msg || "Failed to update feedback";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);

export const updateFeedBackForm = createAsyncThunk<
  void,
  {
    feedbackComment: Nullable<string>;
    feedbackTooSlow: Nullable<boolean>;
    feedbackWrongOutput: Nullable<boolean>;
    generationId: number;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "feedback/updateFeedbackForm",
  async (
    { feedbackComment, feedbackTooSlow, feedbackWrongOutput, generationId },
    { dispatch, getState }
  ) => {
    try {
      const roomId = getState().room.roomId;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const reqData = JSON.stringify({
        feedbackComment,
        feedbackTooSlow,
        feedbackWrongOutput,
        generationId,
      });

      await axios.patch(
        `${process.env.NEXT_PUBLIC_API_URL}/generation/feedbackForm`,
        reqData,
        config
      );

      dispatch(
        generateAlert({
          text: "Feedback updated successfully!",
          type: "success",
        })
      );
      if (roomId) dispatch(getRoomById(roomId));
    } catch (error: any) {
      console.error(error);
      const errorMessage =
        error.response?.data?.errors[0]?.msg || "Failed to update feedback";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);
