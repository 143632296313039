import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Nullable } from "@/types/common";
import { Idea, Generation, ViewMode, TransformationType } from "@/types/thread";
import { UserFollow } from "@/types/social";
import { SubscriptionType } from "@/types/user";

interface RoomState {
  roomId: Nullable<number>;
  roomUUID: Nullable<string>;
  roomUserId: Nullable<number>;
  generations: Generation[];
  jobStatus: Nullable<string>;
  jobType: Nullable<TransformationType>;
  cancelled: Nullable<boolean>;
  cancellationReason: Nullable<string>;
  selectedGeneration: Nullable<number>;
  selectedModel: TransformationType;
  selectedAspectRatio: string;
  viewMode: ViewMode;
  loadingThread: boolean;
  username: string;
  avatarUrl: string;
  subscriptionType: SubscriptionType;
  userFollow: Nullable<UserFollow>;
  cursor: number;
}

export const initialState: RoomState = {
  roomId: null,
  roomUUID: null,
  roomUserId: null,
  generations: [],
  jobStatus: null,
  jobType: null,
  cancelled: null,
  cancellationReason: null,
  selectedGeneration: null,
  selectedModel: TransformationType.Turbo,
  selectedAspectRatio: "1:1",
  viewMode: ViewMode.Public,
  loadingThread: false,
  username: "",
  avatarUrl: "",
  subscriptionType: SubscriptionType.Free,
  userFollow: null,
  cursor: 1,
};

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setRoomProperties(state, action: PayloadAction<Idea>) {
      state.roomId = action.payload.id;
      state.roomUserId = action.payload.userId;
      state.generations = action.payload.generations;
      state.username = action.payload.username;
      state.avatarUrl = action.payload.avatarUrl || "";
      state.subscriptionType = action.payload.subscriptionType;
      state.userFollow = action.payload.userFollow;
    },
    setRoomId(state, action: PayloadAction<Nullable<number>>) {
      state.roomId = action.payload;
    },
    setRoomUUID(state, action: PayloadAction<Nullable<string>>) {
      state.roomUUID = action.payload;
    },
    setRoomUserId(state, action: PayloadAction<Nullable<number>>) {
      state.roomUserId = action.payload;
    },
    setGenerations(state, action: PayloadAction<Generation[]>) {
      state.generations = action.payload;
      state.loadingThread = false;
    },
    resetRoom: (state) => {
      state.roomId = null;
      state.jobStatus = null;
      state.generations = [];
      state.cancelled = null;
      state.cancellationReason = null;
      state.roomUUID = null;
      state.roomUserId = null;
      state.username = "";
      state.avatarUrl = "";
      state.subscriptionType = SubscriptionType.Free;
      state.userFollow = null;
      state.cursor = 1;
    },
    setJobStatus(state, action: PayloadAction<Nullable<string>>) {
      state.jobStatus = action.payload;
    },
    setJobType(state, action: PayloadAction<Nullable<TransformationType>>) {
      state.jobType = action.payload;
    },
    setCancelled: (state, action: PayloadAction<Nullable<boolean>>) => {
      state.cancelled = action.payload;
    },
    setCancellationReason: (state, action: PayloadAction<Nullable<string>>) => {
      state.cancellationReason = action.payload;
    },
    setSelectedGeneration: (state, action: PayloadAction<Nullable<number>>) => {
      state.selectedGeneration = action.payload;
    },
    setSelectedModel: (state, action: PayloadAction<TransformationType>) => {
      state.selectedModel = action.payload;
    },
    setSelectedAspectRatio: (state, action: PayloadAction<string>) => {
      state.selectedAspectRatio = action.payload;
    },
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload;
    },
    setLoadingThread: (state, action: PayloadAction<boolean>) => {
      state.loadingThread = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload;
    },
    setSubscriptionType: (state, action: PayloadAction<SubscriptionType>) => {
      state.subscriptionType = action.payload;
    },
    setUserFollow: (state, action: PayloadAction<Nullable<UserFollow>>) => {
      state.userFollow = action.payload;
    },
    setRoomCursor: (state, action: PayloadAction<number>) => {
      state.cursor = action.payload;
    },
  },
});

export const {
  setRoomProperties,
  setRoomId,
  setRoomUUID,
  setRoomUserId,
  setGenerations,
  resetRoom,
  setJobStatus,
  setJobType,
  setCancelled,
  setCancellationReason,
  setSelectedGeneration,
  setSelectedModel,
  setSelectedAspectRatio,
  setViewMode,
  setLoadingThread,
  setUsername,
  setAvatarUrl,
  setUserFollow,
  setSubscriptionType,
  setRoomCursor,
} = roomSlice.actions;

export default roomSlice.reducer;
