import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { Notification } from "@/types/social";
import { AppDispatch, RootState } from "redux/store";
import { setNotifications, generateAlert, setNotificationsLoading } from ".";

export const getNotifications = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("notifications/getNotifications", async (_, { dispatch, getState }) => {
  dispatch(setNotificationsLoading(true));
  const userId = getState().user.user?.id;
  if (!userId) {
    throw new Error("A user object is expected");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_NOTIFICATIONS_API_URL}/notifications/api/?userId=${userId}`
    );

    dispatch(setNotifications(response.data.notifications));
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to load notifications";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(setNotificationsLoading(false));
  }
});

function findLatestCreatedDate(notifications: Notification[]): string | null {
  if (notifications.length === 0) {
    return null;
  }

  let latestDate = new Date(notifications[0].created);

  for (const notification of notifications) {
    const createdDate = new Date(notification.created);
    if (createdDate > latestDate) {
      latestDate = createdDate;
    }
  }

  return latestDate.toISOString();
}

export const markNotificationsRead = createAsyncThunk<
  void,
  { notifications: Notification[] },
  { dispatch: AppDispatch; state: RootState }
>(
  "notifications/markNotificationsRead",
  async ({ notifications }, { dispatch, getState }) => {
    const userId = getState().user.user?.id;
    if (!userId) {
      throw new Error("A user object is expected");
    }

    const latestCreateDate = findLatestCreatedDate(notifications);
    if (latestCreateDate === null) {
      return;
    }

    const currentNotifications = getState().notifications.notifications;

    const updatedNotifications = currentNotifications.map((notification) => {
      if (!notification.read) {
        return { ...notification, read: true };
      }
      return notification;
    });

    dispatch(setNotifications(updatedNotifications));

    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_NOTIFICATIONS_API_URL}/notifications/api/read/`,
        { userId: userId, until: latestCreateDate }
      );
    } catch (error: unknown) {
      console.error(error);
      let errorMessage = "Failed to load notifications";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);
