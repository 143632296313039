"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { AlertDialogType } from "@/types/alert";
import { User } from "@/types/user";
import { AppDispatch, RootState } from "@/redux/store";
import {
  setUserLoading,
  setUser,
  setIsAuthenticated,
  setAuthLoading,
  generateAlert,
  addAlertDialog,
  setGenerations,
  resetGallery,
  getQuotas,
} from ".";

export const authenticateUser = createAsyncThunk<
  User | null,
  void,
  { dispatch: AppDispatch; state: RootState }
>("auth/authenticateUser", async (_, { dispatch }) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/auth`,
      {
        withCredentials: true,
      }
    );
    const user = response.data;
    dispatch(setIsAuthenticated(true));
    dispatch(setUser(user));
    dispatch(getQuotas());
    return user;
  } catch (error: unknown) {
    let errorMessage = "Failed to authenticate user";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.error(errorMessage);
    dispatch(setAuthLoading(false));
    return null;
  }
});

export const login = createAsyncThunk<
  boolean,
  { email: string; password: string },
  { dispatch: AppDispatch; state: RootState }
>("auth/login", async ({ email, password }, { dispatch }) => {
  try {
    dispatch(setUserLoading(true));
    const reqBody = { email, password };
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/auth`,
      reqBody,
      {
        withCredentials: true,
      }
    );
    const user = response.data;
    dispatch(setUser(user));
    dispatch(setIsAuthenticated(true));
    dispatch(
      generateAlert({
        text: "Login successful!",
        type: "success",
        timeout: 2000,
      })
    );
    return true;
  } catch (error: unknown) {
    let errorMessage = "Failed to login";
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    if (error instanceof AxiosError) {
      error.response?.data?.errors?.forEach((error: { msg: string }) => {
        dispatch(
          generateAlert({
            text: error.msg,
            type: "error",
          })
        );
      });
    } else {
      dispatch(
        generateAlert({
          text: errorMessage,
          type: "error",
        })
      );
    }

    console.error(errorMessage);
    dispatch(setUserLoading(false));
    return false;
  }
});

export const logout = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("auth/logout", async (_, { dispatch }) => {
  await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/auth/logout`, {
    withCredentials: true,
  });
  dispatch(setUser(null));
  dispatch(setIsAuthenticated(false));
  dispatch(setGenerations([]));
  dispatch(resetGallery());
  dispatch(
    generateAlert({
      text: "You have been logged out!",
      type: "success",
    })
  );
});

export const sendPasswordResetLink = createAsyncThunk<
  void,
  { email: string },
  { dispatch: AppDispatch; state: RootState }
>("auth/sendPasswordResetLink", async ({ email }, { dispatch }) => {
  try {
    dispatch(setUserLoading(true));
    const requestBody = { email };

    await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/forgot-password`,
      requestBody
    );
    dispatch(
      generateAlert({ text: "Password reset link sent!", type: "success" })
    );
    dispatch(setUserLoading(false));
  } catch (error: unknown) {
    let errorMessage = "Failed to generate password reset link";
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    if (error instanceof AxiosError) {
      error.response?.data?.errors?.forEach((error: { msg: string }) => {
        dispatch(
          generateAlert({
            text: error.msg,
            type: "error",
          })
        );
      });
    } else {
      dispatch(
        generateAlert({
          text: errorMessage,
          type: "error",
        })
      );
    }

    console.error(errorMessage);
    dispatch(setUserLoading(false));
  }
});

export const resetPassword = createAsyncThunk<
  void,
  { password: string; resetToken: string },
  { dispatch: AppDispatch; state: RootState }
>("auth/resetPassword", async ({ password, resetToken }, { dispatch }) => {
  try {
    dispatch(setUserLoading(true));
    const requestBody = { password, resetToken };
    await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/reset-password`,
      requestBody
    );
    dispatch(
      addAlertDialog({
        title: "Password reset successful!",
        type: AlertDialogType.PasswordResetSuccess,
        prevButtonText: "Login",
        singleOption: true,
      })
    );
    dispatch(setUserLoading(false));
  } catch (error: unknown) {
    console.error(error);
    dispatch(
      generateAlert({ text: "Failed to reset password", type: "error" })
    );
    dispatch(setUserLoading(false));
  }
});
